import { request } from './request'
import { notification } from 'antd'

export const remove = (type: any, id: any, fun: any) => {
    if (window.confirm('Вы уверены, что хотите удалить элемент?')) {
        const data = new FormData()
        data.append('typestructure', type)

        request('delete', `/delete/delete/${id}`, {
            data: `typestructure=${type}`
        })
            .then(result => {
                notification.success({
                    message: 'Успешно',
                    description: 'Элемент успешно удален'
                })
                fun()
            })
            .catch(result => {
                notification.success({
                    message: 'Ошибка',
                    description: 'Элемент не был удален'
                })
            })
    }
}
import { FormEvent } from 'react'
import { Controller } from '../../../helpers/Controller'

export class RatesController extends Controller {

    constructor() {
        super('/user/tarif', '/private/rates')
    }

    save(e: FormEvent) {
        e.preventDefault()

        const data = new FormData()
        data.append('name', this.element.name)
        data.append('cost', this.element.cost)
        data.append('timeactive', this.element.timeactive)
        data.append('counttask', this.element.counttask)
        this.store(data)
    }
}
import {observer} from "mobx-react";
import React, { useEffect } from 'react'
import { RatesController } from './rates.controller'
import { Button, Col, Row, Space, Table, Input, InputNumber, Pagination } from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { PrivateTitle } from '../../../components/PrivateTitle'
import { useParams } from 'react-router-dom'
import { remove } from '../../../helpers/remove'
import { secondsToDays } from '../../../helpers/datetime'
import { NumberHelper } from '../../../helpers/numbers'

const controller = new RatesController()

export const PrivateRates = observer(() => {

    useEffect(() => {
        controller.get()
    }, [])

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            sorter: true,
        },
        { title: 'Наименование', dataIndex: 'name', key: 'name', sorter: true },
        { title: 'Стоимость, р', dataIndex: 'cost', key: 'cost', sorter: true, render: (el: any) => NumberHelper(el) },
        { title: 'Срок действия (дней)', dataIndex: 'timeactive', key: 'timeactive', sorter: true, render: (el: any) => secondsToDays(el) },
        { title: 'Кол-во показаний, шт.', dataIndex: 'counttask', key: 'counttask', sorter: true, render: (el: any) => NumberHelper(el) },
        {
            key: 'action',
            render: (text: any, record: any) => (
                <Space size="middle">
                    <Button
                        type="dashed"
                        shape="circle"
                        icon={<EditOutlined />}
                        href={`/private/rates/${record.id}`}
                    />
                    <Button
                        type="dashed"
                        shape="circle"
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => remove('ksTarif', record.id, () => controller.get())}
                    />
                </Space>
            )
        }
    ]

    return(
        <>
            <PrivateTitle
                title="Тарифы"
                buttons={[
                    { type: "link", title: "Создать", primary: true, link: '/private/rates/0' },
                ]}
            />

            <Table
                columns={columns}
                dataSource={controller.list}
                rowKey="id"
                pagination={false}
                onChange={(pagination: any, filters: any, sorter: any) => controller.onChangeSort(pagination, filters, sorter)}
            />
            <br />
            <Pagination
                defaultCurrent={controller.pagination.page}
                current={controller.pagination.page}
                defaultPageSize={controller.pagination.limit}
                pageSize={controller.pagination.limit}
                total={controller.pagination.total}
                showSizeChanger={false}
                onChange={(e) => controller.setPage(e)}
            />
        </>
    )
})

export const PrivateRatesElement = observer(() => {

    const { elementId } = useParams()

    useEffect(() => {
        controller.getElement(elementId)
    }, [])

    return(
        <>
            <form onSubmit={(e) => controller.save(e)}>
                <PrivateTitle
                    title="Редактирование тарифа"
                    buttons={[
                        { type: "link", title: "Назад", link: '/private/rates' },
                        { type: "submit", title: "Сохранить", primary: true },
                    ]}
                />

                <Row gutter={[20, 20]}>
                    <Col span={12}>
                        <label>Наименование</label>
                        <Input
                            value={controller.element.name}
                            onChange={(e) => controller.onChange('name', e.target.value)}
                            required
                        />
                    </Col>
                    <Col span={12}>
                        <label>Стоимость, р</label>
                        <InputNumber
                            value={controller.element.cost}
                            onChange={(e) => controller.onChange('cost', e)}
                            style={{ width: `100%` }}
                            required
                        />
                    </Col>
                    <Col span={12}>
                        <label>Срок действия (в секундах!)</label>
                        <InputNumber
                            value={controller.element.timeactive}
                            onChange={(e) => controller.onChange('timeactive', e)}
                            style={{ width: `100%` }}
                            required
                        />
                    </Col>
                    <Col span={12}>
                        <label>Кол-во показаний</label>
                        <InputNumber
                            value={controller.element.counttask}
                            onChange={(e) => controller.onChange('counttask', e)}
                            style={{ width: `100%` }}
                            required
                        />
                    </Col>
                </Row>
            </form>
        </>
    )
})
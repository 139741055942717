import { observer } from 'mobx-react'
import { PrivateTitle } from '../../../components/PrivateTitle'
import { LoginController } from '../login/login.controller'
import { useEffect } from 'react'
import { getDateByTimestamp } from '../../../helpers/datetime'
import { NumberHelper } from '../../../helpers/numbers'

const controller = new LoginController()

export const Dashboard = observer(() => {

    useEffect(() => {
        controller.getInfoByCompany()
    }, [])

    return(
        <>
            <PrivateTitle
                title="Рабочий стол"
            />

            <h2>Тариф: {controller.company.tarif.tarif.name}</h2>
            <h4>Осталось показаний: {NumberHelper(controller.company.tarif.countactive)} шт.</h4>
            <h4>Дата окончания тарифа: {getDateByTimestamp(Number(controller.company.tarif.datestart) + Number(controller.company.tarif.tarif.timeactive))}</h4>

            <br/><br/>
            <h4>Активных заданий: {NumberHelper(controller.company.taskactive)}</h4>
            <h4>Завершенные задания: {NumberHelper(controller.company.taskend)}</h4>
            <h4>Контролеров: {NumberHelper(controller.company.controllers)}</h4>
        </>
    )
})
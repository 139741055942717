import { observer } from 'mobx-react'
import { ForgotController } from './forgot.controller'
import { Row, Col, Input, Button, InputNumber } from 'antd'
import React from 'react'

const controller = new ForgotController()

export const Forgot = observer(() => {

    return(
        <div className="private-login">
            <form onSubmit={(e) => controller.forgot(e)}>
                <Row gutter={[10, 10]}>
                    <Col span={24}>
                        <h1>Восстановление пароля</h1>
                    </Col>
                    {controller.step === 0 &&
                        <Col span={24}>
                            <Input
                                placeholder="Email"
                                onChange={(e) => controller.onChangeEmail(e.target.value)}
                                value={controller.email}
                                required
                            />
                        </Col>
                    }
                    {controller.step === 1 &&
                        <Col span={24}>
                            <Input
                                placeholder="Код подтверждения"
                                onChange={(e) => controller.onChangeCode(e.target.value)}
                                value={controller.code}
                                required
                            />
                        </Col>
                    }
                    {controller.step === 2 &&
                        <>
                            <Col span={24}>
                                <Input.Password
                                    placeholder="Пароль"
                                    onChange={(e) => controller.onChangePassword('password', e.target.value)}
                                    value={controller.password.password}
                                    required
                                />
                            </Col>
                            <Col span={24}>
                                <Input.Password
                                    placeholder="Повторите пароль"
                                    onChange={(e) => controller.onChangePassword('passwordConfirm', e.target.value)}
                                    value={controller.password.passwordConfirm}
                                    required
                                />
                            </Col>
                        </>
                    }
                    {controller.step === 3
                        ? <Col span={24}>
                            <Button type="primary" href="/login" block>Авторизация</Button>
                        </Col>
                        :   <Col span={24}>
                                <Button type="primary" htmlType="submit" block>Продолжить</Button>
                            </Col>
                    }

                </Row>
            </form>
        </div>
    )
})
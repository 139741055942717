import { observer } from 'mobx-react'
import { PrivateTitle } from '../../../components/PrivateTitle'
import React, { useEffect } from 'react'
import { TasksController } from './tasks.controller'
import { Col, Row, Progress, Space, Button, Tooltip, Upload, Input, Modal, Table, Pagination } from 'antd'
import { dateByTimestamp } from '../../../helpers/datetime'
import { DeleteOutlined, UserSwitchOutlined, UserAddOutlined, FlagOutlined, FileExcelOutlined, SelectOutlined, CheckSquareOutlined, DownloadOutlined, BorderOutlined } from '@ant-design/icons'
import { UsersController } from '../users/users.controller'
import { LoginController } from '../login/login.controller'
import { NumberHelper } from '../../../helpers/numbers'

const controller = new TasksController()
const users = new UsersController()
const login = new LoginController()

const TaskElement = observer(({ type, data }: any) => {

    return(
        <div className='task' key={`task_${data.id}`}>
            <Row gutter={[7, 20]}>
                <Col span={24} className="progress">
                    <Progress percent={type === 'active'
                        ? data.percent ? data.percent : 0
                        : data.percentend ? data.percentend : 0
                    } size="small" status="active" />
                </Col>
                <Col span={4}>
                    <p className="created">{data.create && dateByTimestamp(data.create)}</p>
                </Col>
                <Col span={4}>
                    <p>№ {data.number}</p>
                </Col>
                <Col span={4}>
                    {data.controller && data.controller[0][0] && <p>{data.controller[0][0].name}</p>}
                </Col>
                <Col span={4}>
                    {data.controller && data.controller[0][0] && <p>{data.controller[0][0].phone}</p>}
                </Col>
                <Col span={4}>
                    <p>{data.customer}</p>
                </Col>
                <Col span={4} className="right">
                    {data.dateactive && data.dateend && <p>{dateByTimestamp(data.dateactive)} - {dateByTimestamp(data.dateend)}</p>}
                </Col>
            </Row>
            <br/>
            <Row justify="space-between" align="bottom">
                <Col span={12}>
                    <p className="stat">
                        {Object.keys(data.stat).map((item, i) => <span key={`list_stat_${item}`}>{item}: {data.stat[item]}</span>)}
                    </p>
                </Col>
                <Col span={12} className="right">
                    {data.id &&
                        <>
                            {type === 'active' &&
                                <Space>
                                    {data.controller && data.controller[0] && data.controller[0][0] && data.controller[0][0].id > 0
                                        ?   <Tooltip title="Сменить контролера">
                                            <Button shape="circle" type="dashed" icon={<UserSwitchOutlined />} onClick={() => controller.changeController(data.id, data.controller[0][0])} />
                                        </Tooltip>
                                        :   <Tooltip title="Назначить контролера">
                                            <Button shape="circle" type="dashed" icon={<UserAddOutlined />} onClick={() => controller.changeController(data.id)} />
                                        </Tooltip>
                                    }
                                    <Tooltip title="Завершить задание">
                                        <Button shape="circle" type="dashed" danger icon={<FlagOutlined />} onClick={() => controller.endTask(data.id)} />
                                    </Tooltip>
                                </Space>
                            }
                            {type === 'completed' &&
                                <Space>
                                    {/*<Tooltip title="Скачать CSV">*/}
                                    {/*    <Button*/}
                                    {/*        shape="circle"*/}
                                    {/*        type="dashed"*/}
                                    {/*        icon={<ProfileOutlined />}*/}
                                    {/*        onClick={() => controller.downloadTasks([ data.id ], 'csv')}*/}
                                    {/*    />*/}
                                    {/*</Tooltip>*/}
                                    <Tooltip title="Скачать Excel">
                                        <Button
                                            shape="circle"
                                            type="dashed"
                                            icon={<img style={{ height: `18px` }} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAACa0lEQVRoge2ZX0hTURzHP1r6sPXPNZs1CHPGZiYUFLuzifTUQ7mC/rzm5uohKqiX/kkPIWIv9RhSStkfIqI/1ENZgchgoj1ULxXYlhb0R22xMkba1oN0xYJM91tnwv28nT+c7/ncc++553LBwCAtcibroJ3ypjKUfLLrQOhwusPkSsxlqiyaWwgpDmmnvU3pjqVEoNHXQNE8m4iEEgHrHCsNNSdEJJQIgJyEMgGQkVAqAOlLKBeA9CSyQgCmL5E1AjA9iawSgD8lJus/+39M6ne2NG8VGyvrVmCqGAKqMQRUYwioRuw9sLsyiKdYA+Bd/D1H79brbZXLPOzy1AGQIsmRO/V8+PJRJFdsBe49b6e00IHL5mT98mq0YrfeFvQEcNmcuGxOXg1GxSYPggL9sX5uPbutl+s0PwDeknWU2VwAJEYTnAu3SEUCws/A2XAr8UQcgJWLy1m7dA1+9069/WLPZdGrD8IC8USctu5Levn4hmOsKCoDYODrAFceX5WMAzKwC117cp03n98CYyfLX5wJNZMYTUjHyQuM/BjhxtObE+pi32Lcf/FAOgrIgEBuTi6byjdOqCswFVDl8EpHjeVJD7i5wofDWgLA8PdhvX5f1R7yZuVJx8kKmPPNBD1+vdzY3kRkMAKAfYGdHau3ScYBwgIBrRaLyQJAZChKR28nF3rGd6WAu5aFZotkpJyAff4Stq8a/1RsCbeSTCV5+PIRfZ/6ADDlmwhqAalIQFBgf/Ve/R6PDr2mo7cTgGQqyfnuNr2fr6KGUqtDKlbh/4F/pOtg6K9znPHHaUNANYaAagwB1RgCBgYznJ8F8rbusqReowAAAABJRU5ErkJggg==" />}
                                            onClick={() => controller.downloadTasks([ data.id ], 'excel')}
                                        />
                                    </Tooltip>
                                    <Tooltip title="Удалить задание">
                                        <Button
                                            shape="circle"
                                            type="dashed"
                                            icon={<DeleteOutlined />}
                                            onClick={() => controller.deleteTasks([ data.id ])}
                                            danger
                                        />
                                    </Tooltip>
                                    <Tooltip title="Выбрать">
                                        <Button
                                            shape="circle"
                                            type={controller.selected.findIndex(el => el === data.id) > -1 ? 'primary' : 'dashed'}
                                            onClick={() => controller.selectTask(data.id)}
                                            icon={controller.selected.findIndex(el => el === data.id) > -1 ? <CheckSquareOutlined /> : <BorderOutlined />}
                                        />
                                    </Tooltip>
                                </Space>
                            }
                        </>
                    }
                </Col>
            </Row>
        </div>
    )
})

const TasksTitle = () => {

    return(
        <Row gutter={[7, 20]} className="task-title" justify="space-between" align="bottom">
            <Col span={4}>
                Дата создания
            </Col>
            <Col span={4}>
                Номер задания
            </Col>
            <Col span={4}>
                ФИО контролера
            </Col>
            <Col span={4}>
                Телефон контролера
            </Col>
            <Col span={4}>
                Заказчик
            </Col>
            <Col span={4} className="right">
                Срок задания
            </Col>
        </Row>
    )
}

export const Tasks = observer(() => {

    const columnsUsers = [
        {
            title: 'ФИО',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Телефон',
            dataIndex: 'phone',
            key: 'phone'
        },
        {
            key: 'action',
            className: 'right',
            render: (text: any, record: any) => (
                <Space size="middle">
                    <Button
                        type="dashed"
                        onClick={() => controller.selectUser(record.login)}
                    >
                        Выбрать
                    </Button>
                </Space>
            )
        }
    ]

    useEffect(() => {
        controller.get(false, { filter: false, field: '', value: '' }, 'filter[status][]=0&filter[status][]=1')
        users.get()
    }, [])

    return(
        <>
            <PrivateTitle
                title="Активные задания"
                buttons={[
                    { type: "link", title: "Создать задание", link: '/tasks/0', primary: true },
                ]}
            />

            <TasksTitle />
            {controller.list.map(el => <TaskElement type="active" data={el} key={`list_tasks_${el.id}`} />)}

            <br />
            <Pagination
                defaultCurrent={controller.pagination.page}
                current={controller.pagination.page}
                defaultPageSize={controller.pagination.limit}
                pageSize={controller.pagination.limit}
                total={controller.pagination.total}
                showSizeChanger={false}
                onChange={(e) => controller.setPage(e)}
            />

            <Modal
                title="Изменение ответственного контролера"
                visible={controller.change}
                onCancel={() => controller.clearChange()}
                footer={false}
            >
                <Row>
                    {controller.changeUser && controller.changeUser.id &&
                        <Col span={24}>
                            <p>Текущий контролер: {controller.changeUser.name} - {controller.changeUser.phone}</p>
                        </Col>
                    }
                    <Col span={24}>
                        <Input.Search
                            placeholder="Поиск по ФИО или телефону"
                            onChange={(value: any) => users.search(value.target.value)}
                            onSearch={(value: any) => users.search(value)}
                        />
                        <br/><br/>
                        <Table
                            columns={columnsUsers}
                            dataSource={users.list}
                            rowKey="id"
                            pagination={false}
                            onChange={(pagination: any, filters: any, sorter: any) => users.onChangeSort(pagination, filters, sorter)}
                        />

                        <br />
                        <Pagination
                            defaultCurrent={users.pagination.page}
                            current={users.pagination.page}
                            defaultPageSize={users.pagination.limit}
                            pageSize={users.pagination.limit}
                            total={users.pagination.total}
                            showSizeChanger={false}
                            onChange={(e) => users.setPage(e)}
                        />
                    </Col>
                </Row>
            </Modal>
        </>
    )
})

export const TasksCompleted = observer(() => {

    useEffect(() => {
        controller.get(false, { filter: false, field: '', value: '' }, 'filter[status][]=2&filter[status][]=3')
    }, [])

    return(
        <>
            <PrivateTitle
                title="Завершенные задания"
            />

            <TasksTitle />
            {controller.list.map(el => <TaskElement type="completed" data={el} key={`list_tasks_${el.id}`} />)}

            <Row>
                <Col>
                    Действия с выбранными заданиями:
                </Col>
                <Col>
                    <Space>
                        {/*<Button*/}
                        {/*    type="dashed"*/}
                        {/*    icon={<ProfileOutlined />}*/}
                        {/*    onClick={() => controller.downloadTasks(controller.selected, 'csv')}*/}
                        {/*>*/}
                        {/*    Скачать CSV*/}
                        {/*</Button>*/}
                        <Button
                            type="dashed"
                            icon={<FileExcelOutlined />}
                            onClick={() => controller.downloadTasks(controller.selected, 'excel')}
                        >
                            Скачать Excel
                        </Button>
                        <Button
                            type="dashed"
                            danger
                            icon={<DeleteOutlined />}
                            onClick={() => controller.deleteTasks(controller.selected)}
                        >
                            Удалить задания
                        </Button>
                        <Button type="dashed" icon={<SelectOutlined />} onClick={() => controller.clearSelectedTasks()}>Сбросить выделение</Button>
                    </Space>
                </Col>
            </Row>

            <br />
            <Pagination
                defaultCurrent={controller.pagination.page}
                current={controller.pagination.page}
                defaultPageSize={controller.pagination.limit}
                pageSize={controller.pagination.limit}
                total={controller.pagination.total}
                showSizeChanger={false}
                onChange={(e) => controller.setPage(e)}
            />
        </>
    )
})

export const Task = observer(() => {

    useEffect(() => {
        login.getInfoByCompany()
    }, [])

    return(
        <>
            <form onSubmit={(e) => controller.save(e)}>
                <PrivateTitle
                    title="Создание задания"
                    buttons={controller.pre.length > 0 ? [] : [
                        { type: "submit", title: "Сохранить", primary: true },
                    ]}
                />

                {controller.pre.length > 0 ?
                    <>
                        {controller.pre.map(el => <TaskElement type="active" data={el} key={`list_tasks_el`} />)}

                        {controller.preCountEnd >= 0
                            ? <p>После нажатия кнопки "СОЗДАТЬ" с тарифа будет списано {NumberHelper(controller.preCount)} показаний из {NumberHelper(login.company.tarif.countactive)}.</p>
                            : <p>После нажатия кнопки "СОЗДАТЬ" с тарифа будет списано {NumberHelper(controller.preCount)} показаний из {NumberHelper(login.company.tarif.countactive)}. У вас не хватает {Math.abs(controller.preCountEnd)} показаний в тарифе. Задание будет создано, но после завершения отчет нельзя будет скачатью Необходимо пополнить баланс показаний.</p>
                        }

                        <Button onClick={() => controller.onConfirm()}>Создать</Button>
                    </>
                    :
                    <>
                        <Row>
                            <Col>
                                <label>Загрузите файл задания</label>
                                <Upload
                                    multiple={true}
                                    fileList={[]}
                                    accept=".xls,.xlsx"
                                    onChange={(e) => controller.onChangeFile(e)}
                                >
                                    <Button icon={<DownloadOutlined />}>Загрузите файл</Button>
                                </Upload>
                                {controller.file && controller.file.map(el => (
                                    <p>{el.name}</p>
                                ))}
                            </Col>
                        </Row>
                    </>
                }


            </form>
        </>
    )
})
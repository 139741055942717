import { observer } from 'mobx-react'
import { PrivateTitle } from '../../../components/PrivateTitle'
import React, { useEffect } from 'react'
import { SettingsController } from './settings.controller'
import { Col, Input, Checkbox, Row } from 'antd'

const controller = new SettingsController()

export const Settings = observer(() => {

    useEffect(() => {
        controller.getElement()
    }, [])

    return(
        <>
            <form onSubmit={(e) => controller.save(e)}>
                <PrivateTitle
                    title="Настройки"
                    buttons={[
                        { type: "submit", title: "Сохранить", primary: true },
                    ]}
                />

                <h3>Данные для входа</h3>
                <Row gutter={[20, 20]}>
                    <Col span={12}>
                        <label>Email</label>
                        <Input
                            value={controller.element.login}
                            onChange={(e) => controller.onChangeElement('login', e.target.value)}
                            required
                        />
                    </Col>
                    <Col/>
                    <Col span={24}>
                        <h3>Сменить пароль</h3>
                    </Col>
                    <Col span={12}>
                        <label>Пароль</label>
                        <Input.Password
                            value={controller.element.password}
                            onChange={(e) => controller.onChangeElement('password', e.target.value)}
                        />
                    </Col>
                    <Col span={12}>
                        <label>Подтверждение пароля</label>
                        <Input.Password
                            value={controller.element.passwordConfirm}
                            onChange={(e) => controller.onChangeElement('passwordConfirm', e.target.value)}
                        />
                    </Col>
                </Row>

                {/*<h3>Уведомления</h3>*/}
                {/*<Row gutter={[20, 20]}>*/}
                {/*    <Col span={24}>*/}
                {/*        <Checkbox*/}
                {/*            onChange={(e) => controller.onChangeNotification('month', e)}*/}
                {/*        >*/}
                {/*            Уведомить об окончании тарифа за 1 месяц*/}
                {/*        </Checkbox>*/}
                {/*    </Col>*/}
                {/*    <Col span={24}>*/}
                {/*        <Checkbox onChange={(e) => controller.onChangeNotification('week', e)}>Уведомить об окончании тарифа за 1 неделю</Checkbox>*/}
                {/*    </Col>*/}
                {/*    <Col span={24}>*/}
                {/*        <Checkbox onChange={(e) => controller.onChangeNotification('day', e)}>Уведомить об окончании тарифа за 1 день</Checkbox>*/}
                {/*    </Col>*/}
                {/*    <Col span={24}>*/}
                {/*        <Checkbox onChange={(e) => controller.onChangeNotification('count', e)}>Уведомить об окончании кол-ва показаний</Checkbox>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
            </form>
        </>
    )
})
import { observer } from 'mobx-react'
import { UsersController } from './users.controller'
import React, { useEffect } from 'react'
import { PrivateTitle } from '../../../components/PrivateTitle'
import { Button, Col, DatePicker, Input, InputNumber, Pagination, Row, Select, Space, Table } from 'antd'
import { getDateByTimestamp } from '../../../helpers/datetime'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { remove } from '../../../helpers/remove'
import { useParams } from 'react-router-dom'
import locale from 'antd/es/date-picker/locale/ru_RU'
import ReactInputMask from 'react-input-mask'

const controller = new UsersController()

export const Users = observer(() => {

    useEffect(() => {
        controller.get()
    }, [])

    const columns = [
        {
            title: 'ФИО',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
        },
        {
            title: 'Телефон',
            dataIndex: 'phone',
            key: 'phone',
            sorter: true,
        },
        {
            key: 'action',
            className: 'right',
            render: (text: any, record: any) => (
                <Space size="middle">
                    <Button
                        type="dashed"
                        shape="circle"
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => controller.removeController(record.id)}
                    />
                </Space>
            )
        }
    ]

    return(
        <>
            <PrivateTitle
                title="Контролеры"
                buttons={[
                    { type: "link", title: "Создать", link: '/users/0', primary: true },
                ]}
            />
            <Input.Search
                placeholder="Поиск по ФИО или телефону"
                onChange={(value: any) => controller.search(value.target.value)}
            />
            <br/><br/>
            <Table
                columns={columns}
                dataSource={controller.list}
                rowKey="id"
                pagination={false}
                onChange={(pagination: any, filters: any, sorter: any) => controller.onChangeSort(pagination, filters, sorter)}
            />

            <br />
            <Pagination
                defaultCurrent={controller.pagination.page}
                current={controller.pagination.page}
                defaultPageSize={controller.pagination.limit}
                pageSize={controller.pagination.limit}
                total={controller.pagination.total}
                showSizeChanger={false}
                onChange={(e) => controller.setPage(e)}
            />
        </>
    )
})

export const User = observer(() => {

    const { elementId } = useParams()

    useEffect(() => {
        controller.getElement(elementId)
    }, [])

    return(
        <>
            <form onSubmit={(e) => controller.save(e)}>
                <PrivateTitle
                    title="Редактирование контролера"
                    buttons={[
                        { type: "link", title: "Назад", link: '/users' },
                        { type: "submit", title: "Сохранить", primary: true },
                    ]}
                />

                <Row gutter={[20, 20]} align="bottom">
                    <Col span={12}>
                        <label>Телефон</label>
                        <ReactInputMask
                            value={controller.element.login}
                            style={{ width: `100%` }}
                            mask="7\9999999999"
                            onChange={(e: any) => controller.onChange('login', e.target.value)}
                            onBlur={() => controller.searchUsers()}
                            required
                        />
                    </Col>
                    <Col span={6}>
                        <Button>Поиск контролера</Button>
                    </Col>
                    <Col />
                    {controller.searchUser &&
                        <>
                            <Col span={12}>
                                <label>ФИО</label>
                                <Input
                                    value={controller.element.name}
                                    onChange={(e) => controller.onChange('name', e.target.value)}
                                    //disabled={!controller.searchUser}
                                    required
                                />
                            </Col>
                            <Col span={12}>
                                <label>Пароль</label>
                                <Input
                                    value={controller.element.password}
                                    onChange={(e) => controller.onChange('password', e.target.value)}
                                    //disabled={!controller.searchUser}
                                    required={!(controller.element.id && controller.element.id > 0)}
                                />
                            </Col>
                        </>
                    }
                    {controller.searchUserList.length > 0 && controller.searchUserList.map(el =>
                        <Col span={24}>
                            <Button onClick={() => controller.selectUser(el.id, el.companyid)}>Выбрать контролера: {el.name} - {el.login}</Button>
                        </Col>
                    )}
                </Row>
            </form>
        </>
    )
})
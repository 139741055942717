export const getDateByTimestamp = (timestamp: number) => {
    if(timestamp > 0) {
        let date = new Date(timestamp * 1000)
        return `${('0' + (date.getUTCDate())).slice(-2)}.${('0' + (date.getUTCMonth() + 1)).slice(-2)}.${('0' + (date.getUTCFullYear())).slice(-2)}`
    } else {
        return 'Закончился'
    }
}

export const dateByTimestamp = (timestamp: number) => {
    let date = new Date(timestamp * 1000)
    return `${('0' + (date.getUTCDate())).slice(-2)}.${('0' + (date.getUTCMonth() + 1)).slice(-2)}.${('0' + (date.getUTCFullYear())).slice(-2)}`
}

export const secondsToDays = (timestamp: number) => {
    return Math.floor(timestamp / (3600*24))
}
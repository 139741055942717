import React, { ReactElement } from 'react'
import { observer } from "mobx-react"
import { Row, Col, Button } from 'antd'
import {
    DashboardOutlined,
    UsergroupAddOutlined,
    StarOutlined,
    BranchesOutlined,
} from '@ant-design/icons'

import '../assets/styles/private.less'
import './style/header.less'
import './style/private-login.less'
import './style/nav.less'
import {getPrivatePermissions} from "../helpers/permissions";
import {privateLoginController} from '../pages/private/login/login.controller'

const controller = new privateLoginController()

const Navigation = observer(() => {

    const nav = [
        {
            key: "navigation_dashboard",
            items: [
                { title: 'Рабочий стол', url: "/private", icon: <DashboardOutlined /> },
                { title: 'Клиенты', url: "/private/clients", icon: <UsergroupAddOutlined /> },
                { title: 'Тарифы', url: "/private/rates", icon: <StarOutlined /> },
                { title: 'Типы счетчиков', url: "/private/types", icon: <BranchesOutlined /> },
            ],
            title: undefined
        },
    ]

    return(
        <div className="navigation">
            <ul>
                {nav.map(item => (
                    <div key={item.key}>
                        {(item.title || item.title === "") &&
                            <div>
                                <hr/>
                                {item.title !== "" && <p>{ item.title }</p>}
                            </div>
                        }
                        {item.items.map((el, i) => (
                            <li key={`${item.key}_item_${i}`}>
                                <a href={el.url}>
                                    {el.icon} { el.title }
                                </a>
                            </li>
                        ))}
                    </div>
                ))}
            </ul>
        </div>
    )
})

const Header = observer(() => {

    return(
        <header>
            <div className="default">
                <Row justify="space-around" align="middle">
                    <Col span={6} className="logo">
                        <span>Контролька</span>
                    </Col>
                    <Col span={18} className="user">
                        <Button onClick={() => controller.logout()}>Выйти</Button>
                    </Col>
                </Row>
            </div>
        </header>
    )
})

const Footer = observer(() => {

    return(
        <>

        </>
    )
})

export const PrivateLayout = observer(({ content }: { content: ReactElement }) => {

    return getPrivatePermissions()
        ?   (
                <>
                    <Header />
                    <div className="default">
                        <Row>
                            <Col span={6}>
                                <Navigation />
                            </Col>
                            <Col span={18} className="default-content">
                                { content }
                            </Col>
                        </Row>
                    </div>
                    <Footer />
                </>
            )
        :   (
                <>
                    <Row className="login-row" justify="space-around" align="middle">
                        <Col
                            xs={{ span: 22 }}
                            sm={{ span: 16 }}
                            md={{ span: 10 }}
                            lg={{ span: 7 }}
                            xl={{ span: 5 }}
                        >
                            { content }
                        </Col>
                    </Row>
                </>
            )
})
import React from 'react'
import {observer} from "mobx-react";
import {privateLoginController} from "./login.controller";
import {Button, Col, Input, Row} from "antd";

const login = new privateLoginController()

export const PrivateLogin = observer(() => {



    return(
        <>
            <div className="private-login">
                <form onSubmit={(e) => login.login(e)}>
                    <Row gutter={[10, 10]}>
                        <Col span={24}>
                            <h1>Авторизация</h1>
                            <h2>В административной части</h2>
                        </Col>
                        <Col span={24}>
                            <Input
                                placeholder="Логин"
                                onChange={(e) => login.onChange('username', e.target.value)}
                                value={login.data.username}
                                name="username"
                                required
                            />
                        </Col>
                        <Col span={24}>
                            <Input.Password
                                placeholder="Пароль"
                                onChange={(e) => login.onChange('password', e.target.value)}
                                value={login.data.password}
                                name="password"
                                required
                            />
                        </Col>
                        <Col span={24}>
                            <Button type="primary" htmlType="submit" block>Войти</Button>
                        </Col>
                    </Row>
                </form>
            </div>
        </>
    )
})
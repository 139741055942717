import React from 'react'
import { Controller } from '../../../helpers/Controller'
import { FormEvent } from 'react'
import { makeObservable, observable } from 'mobx'
import { UsersController } from '../users/users.controller'
import { request } from '../../../helpers/request'
import { notification } from 'antd'

const user = new UsersController()

export class TasksController extends Controller {

    file: any[] = []
    selected: any[] = []

    pre: any[] = []
    preCount: number = 0
    preCountEnd: number = 0

    change = false
    changeTaskId = 0
    changeUser: any = {}

    constructor() {
        super('/task/tasknumber', '/tasks')
        makeObservable(this, {
            file: observable,
            change: observable,
            selected: observable,
            pre: observable,
            preCount: observable,
            preCountEnd: observable,
            changeUser: observable
        })
    }

    selectTask(taskId: any) {
        if(this.selected.findIndex(el => el === taskId) > -1) {
            this.selected = this.selected.filter(el => el !== taskId)
        } else {
            this.selected.push(taskId)
        }

        console.log(this.selected)
    }

    clearSelectedTasks() {
        this.selected = []
    }

    changeController(taskId: any, contr: any = {}) {
        this.changeTaskId = taskId
        this.change = true
        this.changeUser = contr
    }

    clearChange() {
        this.change = false
        this.changeUser = {}
    }

    selectUser(phone: any) {
        console.log(phone, this.changeTaskId)

        const data = new FormData()
        data.append('newphone', phone)

        console.log(this.changeTaskId)

        request('post', `/task/taskupdatephone/${this.changeTaskId}`, data)
            .then(result => {
                console.log(result)
                this.clearChange()
                this.get(false, { filter: false, field: '', value: '' }, 'filter[status][]=0&filter[status][]=1')
            })
    }

    endTask(taskId: any) {
        if (window.confirm('Вы уверены, что хотите завершить задание?')) {
            request('post', `/task/tasknumberclose/${taskId}`)
                .then(result => {
                    notification.success({
                        message: 'Успешно',
                        description: `Задание успешно завершено`
                    })
                    this.get(false, { filter: false, field: '', value: '' }, 'filter[status][]=0&filter[status][]=1')
                })
        }
    }

    onChangeFile(e: any) {
        console.log(e.fileList)
        this.file = e.fileList
    }

    onConfirm() {
        if(!this.element.id || this.element.id < 1) this.element.id = 0

        const data = new FormData()
        this.file.map(el => data.append('file[]', el.originFileObj))

        this.store(data)
    }

    save(e: FormEvent) {
        e.preventDefault()

        if(!this.element.id || this.element.id < 1) this.element.id = 0

        const data = new FormData()
        this.file.map(el => data.append('file[]', el.originFileObj))

        request('post', '/task/tasknumbercount', data)
            .then(success => {
                console.log(success)
                this.pre = success.tasknumber
                this.preCount = success.countcounter
                this.preCountEnd = success.countactive

                console.log(this.pre.length)
            })
    }

    deleteTasks(tasks: any[]) {
        const data = new FormData()

        tasks.map(el => data.append('tasknumberids[]', el))

        request('post', '/task/tasknumberdelete', data)
            .then(result => {
                console.log(result)
                this.get(false, { filter: false, field: '', value: '' }, 'filter[status][]=2&filter[status][]=3')
            })

        this.clearSelectedTasks()
    }

    downloadTasks(tasks: any[], type: string) {
        const data = new FormData()
        data.append('type', type)
        tasks.map(el => {
            data.append('tasknumberids[]', el)
        })


        request('post', '/task/zip', data)
            .then(result => {
                console.log(result)
                let link = document.createElement('a')
                link.setAttribute('href',result)
                link.setAttribute('tasks','tasks')
                link.click()
            })

        this.clearSelectedTasks()
    }

}
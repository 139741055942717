import { makeAutoObservable } from 'mobx'
import { FormEvent } from 'react'
import { notification } from 'antd'
import { request } from '../../../helpers/request'
import store from 'store'
import sha1 from 'sha1'

export class SettingsController {

    element = {
        login: '',
        password: '',
        passwordConfirm: ''
    }

    notifications = {
        month: false,
        week: false,
        day: false,
        count: false,
        countNumber: 1000,
    }

    constructor() {
        makeAutoObservable(this)
    }

    getElement() {
        request('get', `/user/company/${store.get('public').id}`)
            .then(result => {
                this.element.login = result.login
            })
    }

    getNotifications() {

    }

    onChangeElement(name: string, value: string) {
        // @ts-ignore
        this.element[name] = value
    }

    onChangeNotification(name: string, value: any) {
        // @ts-ignore
        this.notifications[name] = value
    }

    save(e: FormEvent) {
        e.preventDefault()

        const data = new FormData()
        data.append('login', this.element.login)
        if(this.element.password) {
            if(this.element.password !== '' && this.element.passwordConfirm !== '') {
                if(this.element.password !== this.element.passwordConfirm) {
                    notification.error({
                        message: 'Ошибка',
                        description: 'Введенные пароли не совпадают'
                    })
                } else {
                    data.append('password', sha1(this.element.password))
                }
            }
        }

        request('post', `/user/company/${store.get('public').id}`, data)
            .then(result => {
                notification.success({
                    message: 'Успешно',
                    description: 'Данные успешно изменены'
                })
            })

    }

}
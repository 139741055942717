import React, {useEffect} from 'react'
import { observer } from "mobx-react"
import { clientsPrivateController } from './clients.private.controller'
import { RatesController } from '../rates/rates.controller'
import { Space, Button, Table, Pagination, Col, Input, Select, Row, DatePicker, InputNumber } from 'antd'

import locale from 'antd/es/date-picker/locale/ru_RU';

import {
    EditOutlined,
    DeleteOutlined
} from '@ant-design/icons'
import { PrivateTitle } from '../../../components/PrivateTitle'
import { remove } from '../../../helpers/remove'
import { useParams } from 'react-router-dom'
import { getDateByTimestamp } from '../../../helpers/datetime'
import { NumberHelper } from '../../../helpers/numbers'

const controller = new clientsPrivateController()
const ratesController = new RatesController()

export const PrivateClients = observer(() => {

    useEffect(() => {
        controller.get()
    }, [])

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            sorter: true,
        },
        {
            title: 'Название организации',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
        },
        { title: 'Тарифный план', dataIndex: 'tarifcompany', key: 'tarifcompany', render: (item:any) => <>{item.tarif.name}</> },
        { title: 'Осталось показаний, шт', dataIndex: 'tarifcompany', key: 'id', render: (item:any) => NumberHelper(item.countactive) },
        {
            title: 'Срок действия тарифа, до',
            dataIndex: 'tarifcompany',
            key: 'tarifcompany_time',
            render: (item: any, record:any) => getDateByTimestamp(Number(item.datestart) + Number(item.tarif.timeactive))
        },
        {
            key: 'action',
            className: 'right',
            render: (text: any, record: any) => (
                <Space size="middle">
                    <Button
                        type="dashed"
                        shape="circle"
                        icon={<EditOutlined />}
                        href={`${controller.url}/${record.id}`}
                    />
                    <Button
                        type="dashed"
                        shape="circle"
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => remove('ksCompany', record.id, () => controller.get())}
                    />
                </Space>
            )
        }
    ]

    return(
        <>
            <PrivateTitle
                title="Клиенты"
                buttons={[
                    { type: "link", title: "Создать", link: '/private/clients/0', primary: true },
                ]}
            />

            <Input.Search
                placeholder="Поиск по названию"
                onSearch={(value: any) => controller.search(value)}
            />
            <br/><br/>

            <Table
                columns={columns}
                dataSource={controller.list}
                rowKey="id"
                pagination={false}
                onChange={(pagination: any, filters: any, sorter: any) => controller.onChangeSort(pagination, filters, sorter)}
            />

            <br />
            <Pagination
                defaultCurrent={controller.pagination.page}
                current={controller.pagination.page}
                defaultPageSize={controller.pagination.limit}
                pageSize={controller.pagination.limit}
                total={controller.pagination.total}
                showSizeChanger={false}
                onChange={(e) => controller.setPage(e)}
            />
        </>
    )
})

export const PrivateClientsElement = observer(() => {

    const { elementId } = useParams()

    useEffect(() => {
        controller.customGetElement(elementId)
        ratesController.get(true)
    }, [])

    return(
        <>
            <form onSubmit={(e) => controller.save(e)}>
                <PrivateTitle
                    title="Редактирование клиента"
                    buttons={[
                        { type: "link", title: "Назад", link: '/private/clients' },
                        { type: "submit", title: "Сохранить", primary: true },
                    ]}
                />

                <Row gutter={[20, 20]}>
                    <Col span={12}>
                        <label>Наименование</label>
                        <Input
                            value={controller.element.name}
                            onChange={(e) => controller.onChange('name', e.target.value)}
                            required
                        />
                    </Col>
                    <Col span={12}>
                        <label>Email</label>
                        <Input
                            value={controller.element.login}
                            onChange={(e) => controller.onChange('login', e.target.value)}
                            required
                        />
                    </Col>
                    <Col span={12}>
                        <label>Пароль</label>
                        <Input.Password
                            value={controller.element.password}
                            onChange={(e) => controller.onChange('password', e.target.value)}
                            required={controller.element.id <= 0}
                        />
                    </Col>
                    {controller.element.id > 0 && controller.element.tarifcompany &&
                        <>
                            <Col span={12}>
                                <label>Дата начала действия тарифа</label>
                                <DatePicker
                                    locale={locale}
                                    style={{ width: `100%` }}
                                    // value={controller.element.datestart}
                                    onChange={(date: any) => controller.onChange('datestart', date.unix())}
                                />
                            </Col>
                            <Col span={12}>
                                <label>Осталось показаний</label>
                                <InputNumber
                                    value={controller.element.countactive}
                                    style={{ width: `100%` }}
                                    onChange={(e) => controller.onChange('countactive', e)}
                                    required
                                />
                            </Col>
                        </>
                    }
                    <Col span={12}>
                        <label>Тариф</label>
                        <Select
                            style={{ width: `100%` }}
                            value={controller.element.tarifid}
                            onChange={(e) => controller.onChange('tarifid', e)}
                        >
                            {ratesController.list.map((el:any, i: number) =>
                                <Select.Option
                                    value={el.id}
                                    key={`select_rates_${i}`}
                                >
                                    {el.name}
                                </Select.Option>
                            )}
                        </Select>
                    </Col>
                    {controller.element.id < 1 &&
                        <>
                            <Col span={12}>
                                <label>Дата начала действия тарифа</label>
                                <DatePicker
                                    locale={locale}
                                    style={{ width: `100%` }}
                                    // value={controller.element.datestart}
                                    onChange={(date: any) => controller.onChange('datestart', date.unix())}
                                />
                            </Col>
                        </>
                    }
                </Row>

            </form>
        </>
    )
})
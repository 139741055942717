import { makeObservable, observable } from 'mobx'
import { request } from './request'
import { notification, message } from 'antd'

export class Controller {

    path = ''
    url = ''
    list: any[] = []
    element: any = {}

    pagination = { total: 0, limit: 10, page: 1, offset: 0 }

    sorter = { field: 'id', order: 'descend' }

    constructor(path: string, url: string) {
        this.path = path
        this.url = url

        makeObservable(this, {
            sorter: observable,
            list: observable,
            element: observable,
            pagination: observable,
            url: observable,
        })
    }

    onChangeSort(pagination: any, filters: any, sorter: any) {
        this.sorter = {
            field:  sorter.field,
            order: sorter.order || 'descend'
        }
        this.get()
    }

    onChange(name: string, value: any) {
        console.log(value)
        // @ts-ignore
        this.element[name] = value
    }

    clear() {
        this.get()
    }

    setPage(page: number) {
        this.pagination.page = page
        if (page > 0) {
            this.pagination.offset =
                this.pagination.limit * page - this.pagination.limit
        } else {
            this.pagination.offset = 0
        }
        this.get()
    }

    get(all = false, filter = { filter: false, field: '', value: '' }, params: string = '') {
        let path = `${this.path}?limit=${all ? 1000 : this.pagination.limit}&offset=${this.pagination.offset}&sort=${this.sorter.field}&sortby=${this.sorter.order === 'descend' ? 'desc' : 'asc'}`

        if(filter.filter) {
            path = `${path}&${filter.field}=${filter.value}`
        }

        if(params !== '')
            path = `${path}&${params}`

        request('get', path)
            .then(result => {
                this.list = result[0]
                this.pagination.total = result[1]
            })
    }

    getElement(id: any) {
        this.element.id = id
        if(id > 0) {
            request('get', `${this.path}/${id}`)
                .then(result => {
                    this.element = result
                })
        }
    }

    copyError(error: any) {
        navigator.clipboard.writeText(error)
        message.success('Текст ошибки скопирован в буфер обмена');
    }

    async store(data: any) {
        request('post', `${this.path}/${this.element.id}`, data)
            .then(result => {
                console.log(result)

                if(typeof result === 'string') {
                    notification.error({
                        message: 'Ошибка сохранения',
                        description: 'Элемент не сохранен',
                        onClick: () => this.copyError(result)
                    })
                } else {
                    if(this.element.id > 0) {
                        this.element = result
                        notification.success({
                            message: 'Успешно',
                            description: 'Элемент успешно сохранен'
                        })
                    } else {
                        window.location.href = `${this.url}`
                    }
                }
            })
            .catch(result => {
                notification.error({
                    message: 'Ошибка сохранения',
                    description: 'Элемент не сохранен',
                    onClick: () => this.copyError(result)
                })
            })
    }

}
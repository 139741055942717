import { Controller } from '../../../helpers/Controller'
import { FormEvent } from 'react'
import sha1 from 'sha1'
import { request } from '../../../helpers/request'
import { notification } from 'antd'

export class clientsPrivateController extends Controller {

    constructor() {
        super('/user/company', '/private/clients')
    }

    customGetElement(elementId: any) {
        this.element.id = elementId
        if(elementId > 0) {
            request('get', `${this.path}/${elementId}`)
                .then(result => {
                    this.element = result

                    if(result.tarifcompany) {
                        this.element.countactive = result.tarifcompany.countactive
                        this.element.datestart = result.tarifcompany.datestart
                        this.element.tarifcompanyid = result.tarifcompany.id
                        this.element.tarifid = result.tarifcompany.tarif.id
                    }
                })
        }
    }

    search(value: any) {
        this.get(false, {
            filter: true,
            field: 'search',
            value: value
        })
    }

    save(e: FormEvent) {
        e.preventDefault()

        const data = new FormData()
        data.append('name', this.element.name)
        data.append('login', this.element.login)
        data.append('datestart', this.element.datestart)
        data.append('tarifid', this.element.tarifid)

        if(this.element.password && this.element.password !== '') data.append('password', sha1(this.element.password))

        if(this.element.id && this.element.id > 0) {
            data.append('countactive', this.element.countactive)
            data.append('tarifcompanyid', this.element.tarifcompanyid)
        }

        request('post', `${this.path}/${this.element.id}`, data)
            .then(result => {
                console.log(result)
                // return false
                if(this.element.id > 0) {
                    this.customGetElement(this.element.id)
                    notification.success({
                        message: 'Успешно',
                        description: 'Элемент успешно сохранен'
                    })
                } else {
                    window.location.href = `${this.url}`
                }
            })
            .catch(result => {
                notification.error({
                    message: 'Ошибка сохранения',
                    description: 'Элемент не сохранен'
                })
            })
    }
}
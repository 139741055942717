import React from 'react'
import {
    BrowserRouter,
    Routes,
    Route,
} from 'react-router-dom'

import { PublicLayout } from './layouts/Public.layout'
import { PrivateLayout } from "./layouts/Private.layout"

import { Login } from './pages/public/login/Login'

import { PrivateClients, PrivateClientsElement } from './pages/private/clients/PrivateClients'
import {PrivateLogin} from "./pages/private/login/PrivateLogin";
import { PrivateRates, PrivateRatesElement } from './pages/private/rates/PrivateRates'
import { PrivateTypes, PrivateTypesElement } from './pages/private/types/PrivateTypes'
import { PrivateDashboard } from './pages/private/dashboard/PrivateDashboard'
import { NotFound } from './pages/NotFound'
import { Dashboard } from './pages/public/dashboard/Dashboard'
import { Forgot } from './pages/public/login/Forgot'
import { Settings } from './pages/public/settings/Settings'
import { Task, Tasks, TasksCompleted } from './pages/public/tasks/Tasks'
import { User, Users } from './pages/public/users/Users'

export const Router = () => {

    const privateRouter = [
        { path: '/private', element: <PrivateDashboard /> },
        { path: '/private/login', element: <PrivateLogin /> },
        { path: '/private/clients', element: <PrivateClients /> },
        { path: '/private/clients/:elementId', element: <PrivateClientsElement /> },
        { path: '/private/rates', element: <PrivateRates /> },
        { path: '/private/rates/:elementId', element: <PrivateRatesElement /> },
        { path: '/private/types', element: <PrivateTypes /> },
        { path: '/private/types/:elementId', element: <PrivateTypesElement /> },
    ]

    const publicRouter = [
        { path: '/', element: <Dashboard /> },
        { path: '/login', element: <Login /> },
        { path: '/forgot', element: <Forgot /> },
        { path: '/settings', element: <Settings /> },
        { path: '/tasks', element: <Tasks /> },
        { path: '/tasks/completed', element: <TasksCompleted /> },
        { path: '/tasks/:elementId', element: <Task /> },
        { path: '/users', element: <Users /> },
        { path: '/users/:elementId', element: <User /> },

    ]

    return(
        <BrowserRouter>
            <Routes>
                {publicRouter.map((el, i) => <Route path={ el.path } key={`private_router_${i}`} element={<PublicLayout content={el.element}/> } />)}
                {privateRouter.map((el, i) => <Route path={ el.path } key={`public_router_${i}`} element={<PrivateLayout content={el.element}/> } />)}
                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    )
}
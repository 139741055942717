import React, { ReactElement, useEffect } from 'react'
import { autorun } from 'mobx'
import { publicMiddleware } from '../middleware/public.middleware'
import { getPublicPermissions } from '../helpers/permissions'
import { Button, Col, Row, Tooltip } from 'antd'
import { observer } from 'mobx-react'
import store from 'store'
import { LoginController } from '../pages/public/login/login.controller'
import {
    CarryOutOutlined,
    DashboardOutlined,
    LogoutOutlined,
    ContainerOutlined,
    UserOutlined,
    SettingOutlined
} from '@ant-design/icons'
import { getDateByTimestamp } from '../helpers/datetime'
import { NumberHelper } from '../helpers/numbers'


const controller = new LoginController()

const Header = observer(() => {

    useEffect(() => {
        if(store.get('public')) {
            controller.getInfoByCompany()
        }
    }, [])

    return(
        <header>
            <div className="default">
                <Row justify="space-around" align="middle">
                    <Col span={6} className="logo">
                        <span>Контролька</span>
                    </Col>
                    <Col span={18} className="user">
                        <Row gutter={[20, 20]} justify="space-around" align="middle">
                            <Col span={12}>
                                <p className="count">Осталось показаний: {NumberHelper(controller.company.tarif.countactive)} шт.</p>
                                <p className="count">Дата окончания тарифа: {getDateByTimestamp(Number(controller.company.tarif.datestart) + Number(controller.company.tarif.tarif.timeactive))}</p>
                            </Col>
                            <Col span={10} className="right">
                                <p className="company"><span>{store.get('public').name}</span></p>
                            </Col>
                            <Col span={2} className="right">
                                <Tooltip title="Выйти">
                                    <Button type="dashed" shape="circle" icon={<LogoutOutlined />} onClick={() => controller.logout()} />
                                </Tooltip>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </header>
    )
})

const Navigation = observer(() => {

    const nav = [
        {
            key: "navigation_dashboard",
            items: [
                { title: 'Рабочий стол', url: "/", icon: <DashboardOutlined /> },
                { title: 'Активные задания', url: "/tasks", icon: <ContainerOutlined /> },
                { title: 'Завершенные задания', url: "/tasks/completed", icon: <CarryOutOutlined /> },
                { title: 'Контролеры', url: "/users", icon: <UserOutlined /> },
                { title: 'Настройки', url: "/settings", icon: <SettingOutlined /> },
            ],
            title: undefined
        },
    ]

    return(
        <div className="navigation">
            <ul>
                {nav.map(item => (
                    <div key={item.key}>
                        {(item.title || item.title === "") &&
                            <div>
                                <hr/>
                                {item.title !== "" && <p>{ item.title }</p>}
                            </div>
                        }
                        {item.items.map((el, i) => (
                            <li key={`${item.key}_item_${i}`}>
                                <a href={el.url}>
                                    {el.icon} { el.title }
                                </a>
                            </li>
                        ))}
                    </div>
                ))}
            </ul>
        </div>
    )
})

export const PublicLayout = ({ content }: { content: ReactElement }) => {

    return getPublicPermissions() ? (
        <>
            <Header />
            <div className="default">
                <Row>
                    <Col span={6}>
                        <Navigation />
                    </Col>
                    <Col span={18} className="default-content">
                        { content }
                    </Col>
                </Row>
            </div>
        </>
    ) : (
        <>
            <Row className="login-row" justify="space-around" align="middle">
                <Col
                    xs={{ span: 22 }}
                    sm={{ span: 16 }}
                    md={{ span: 10 }}
                    lg={{ span: 7 }}
                    xl={{ span: 5 }}
                >
                    { content }
                </Col>
            </Row>
        </>
    )
}
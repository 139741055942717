import { makeAutoObservable } from 'mobx'
import { FormEvent } from 'react'
import { request } from '../../../helpers/request'
import { notification } from 'antd'
import sha1 from 'sha1'

export class ForgotController {

    step = 0
    email = ''
    code = ''
    companyId = 0;
    password = {
        password: '',
        passwordConfirm: ''
    }

    constructor() {
        makeAutoObservable(this)
    }

    onChangeEmail(value: string) {
        this.email = value
    }

    onChangeCode(value: string) {
        this.code = value
    }

    setStep(value: number) {
        this.step = value
    }

    onChangePassword(name: string, value: string) {
        // @ts-ignore
        this.password[name] = value
    }

    forgot(e: FormEvent) {
        e.preventDefault()

        const data = new FormData()

        if(this.step === 0) {
            data.append('email', this.email)

            request('post', '/user/forgotcompany', data)
                .then(result => {
                    if(result) {
                        notification.success({
                            message: 'Успешно',
                            description: `Мы отправили Вам код подтверждения на почту ${this.email}`
                        })
                        this.setStep(1)
                    } else {
                        notification.error({
                            message: 'Ошибка восстановления пароля',
                            description: 'Проверьте правильность введенных данных'
                        })
                    }
                })
                .catch(error => {
                    notification.error({
                        message: 'Ошибка восстановления пароля',
                        description: 'Проверьте правильность введенных данных'
                    })
                })
        } else if(this.step === 1) {
            data.append('email', this.email)
            data.append('code', `${this.code}`)

            request('post', '/user/forgotcompany', data)
                .then(result => {
                    console.log(result)
                    if(result && result.id && result.id > 0) {
                        this.companyId = result.id
                        notification.success({
                            message: 'Успешно',
                            description: `Придумайте новый пароль`
                        })
                        this.setStep(2)
                    } else {
                        notification.error({
                            message: 'Ошибка восстановления пароля',
                            description: 'Неверный код подтверждения'
                        })
                    }
                })
                .catch(error => {
                    notification.error({
                        message: 'Ошибка восстановления пароля',
                        description: 'Неверный код подтверждения'
                    })
                })
        } else if(this.step === 2) {
            if(this.password.password !== '' && this.password.passwordConfirm !== '') {
                if(this.password.password === this.password.passwordConfirm) {
                    data.append('password', sha1(this.password.password))

                    request('post', `/user/company/${this.companyId}`, data)
                        .then(result => {
                            notification.success({
                                message: 'Успешно',
                                description: `Пароль успешно изменен.`
                            })
                            this.setStep(3)
                        })
                        .catch(error => {
                            notification.error({
                                message: 'Ошибка восстановления пароля',
                                description: 'Произошла ошибка на сервере'
                            })
                        })
                } else {
                    notification.error({
                        message: 'Ошибка',
                        description: 'Введенные пароли не совпадают'
                    })
                }
            } else {
                notification.error({
                    message: 'Ошибка',
                    description: 'Заполните все обязательные поля'
                })
            }

        }
    }

}
import store from 'store'

export const getPrivatePermissions = () => {
    if(store.get('private'))
        return true
    else {
        if(window.location.pathname !== '/private/login')
            window.location.href = '/private/login'
        return false
    }
}

export const getPublicPermissions = () => {
    if(store.get('public'))
        return true
    else {
        if(window.location.pathname !== '/login' && window.location.pathname !== '/forgot')
            window.location.href = '/login'

        return false
    }
}
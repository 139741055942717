import { Controller } from '../../../helpers/Controller'
import { FormEvent } from 'react'
import store from 'store'
import { request } from '../../../helpers/request'
import { makeObservable, observable } from 'mobx'
import { notification } from 'antd'

export class UsersController extends Controller{

    searchUser: boolean = false
    searchUserList: any[] = []

    constructor() {
        super('/user/controllers', '/users')
        makeObservable(this, {
            searchUser: observable,
            searchUserList: observable,
        })
    }

    search(value: any) {
        this.get(false, {
            filter: true,
            field: 'filter[search]',
            value: value
        })
    }

    searchUsers() {
        if(this.element.login.length >= 3)
            request('get', `/user/controllers?filter[search]=${this.element.login}`, {}, false)
                .then(result => {
                    console.log(result[1])
                    if(result[1] === 0) {
                        this.searchUser = true
                        this.searchUserList = []
                    } else {
                        this.searchUser = false
                        this.searchUserList = result[0]
                    }
                })
    }

    removeController(elementId: any) {
        request('delete', `${this.path}/${elementId}`)
            .then(result => {
                if(this.element.id > 0) {
                    this.element = result
                    notification.success({
                        message: 'Успешно',
                        description: 'Элемент успешно удален'
                    })
                } else {
                    window.location.href = `${this.url}`
                }
            })
    }

    selectUser(userId: any, array: any[]) {

        array.push(store.get('public').id)
        const data = new FormData()
        data.append('id', userId)
        array.map(el => data.append('companyid[]', el))

        request('post', `${this.path}/${userId}`, data)
            .then(result => {
                if(this.element.id > 0) {
                    this.element = result
                    notification.success({
                        message: 'Успешно',
                        description: 'Элемент успешно сохранен'
                    })
                } else {
                    window.location.href = `${this.url}`
                }
            })
            .catch(result => {
                notification.error({
                    message: 'Ошибка сохранения',
                    description: 'Элемент не сохранен'
                })
            })
    }

    save(e: FormEvent) {
        e.preventDefault()

        const data = new FormData()
        data.append('name', this.element.name)
        if(this.element.password) data.append('password', this.element.password)
        else if(!this.element.password || this.element.password === '') data.append('password', this.element.login.substring(this.element.login.length - 5))
        data.append('phone', this.element.login)
        data.append('companyid', store.get('public').id)
        this.store(data)
            .then(result => {
                window.location.href = '/users'
            })
    }

}
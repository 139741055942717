import React from 'react'
import './login.less'
import { Button, Col, Input, Row } from 'antd'
import { LoginController } from './login.controller'
import { observer } from 'mobx-react'

const controller = new LoginController()

export const Login = observer(() => {

    return(
        <div className="private-login">
            <form onSubmit={(e) => controller.login(e)}>
                <Row gutter={[10, 10]}>
                    <Col span={24}>
                        <h1>Авторизация компании</h1>
                    </Col>
                    <Col span={24}>
                        <Input
                            placeholder="Логин"
                            onChange={(e) => controller.onChange('username', e.target.value)}
                            value={controller.data.username}
                            name="username"
                            required
                        />
                    </Col>
                    <Col span={24}>
                        <Input.Password
                            placeholder="Пароль"
                            onChange={(e) => controller.onChange('password', e.target.value)}
                            value={controller.data.password}
                            name="password"
                            required
                        />
                    </Col>
                    <Col span={24}>
                        <Button type="primary" htmlType="submit" block>Войти</Button>
                    </Col>
                    <Col span={24}>
                        <Button type="dashed" href="/forgot" block>Восстановление пароля</Button>
                    </Col>
                </Row>
            </form>
        </div>
    )
})
import { Col, Row, Button, Space } from 'antd'

export const PrivateTitle = ({ title, buttons = [] }: any) => {

    return(
        <Row justify="space-around" align="middle" className="private-title">
            <Col span={buttons.length > 0 ? 12 : 24} className="left">
                <h1>{title}</h1>
            </Col>
            {buttons.length > 0 &&
                <Col span={12} className="right">
                    <Space>
                        {buttons.map((item: any, count: number) => item.type === "link"
                            ?   <Button href={item.link} key={`template_title_link_${item.title}`} type={item.primary ? 'primary' : 'dashed'}>{ item.title }</Button>
                            :   item.type === "button"
                                ?   <Button onClick={item.ref} key={`template_title_button_${item.title}`} type={item.primary ? 'primary' : 'dashed'}>{ item.title }</Button>
                                :   item.type === "submit"
                                &&   <Button htmlType="submit" key={`template_title_submit_${item.title}`} type={item.primary ? 'primary' : 'dashed'}>{ item.title }</Button>)}
                    </Space>
                </Col>
            }
        </Row>
    )
}
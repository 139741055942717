import { makeAutoObservable } from 'mobx'
import {FormEvent} from "react";
import sha1 from "sha1";
import {request} from "../../../helpers/request";
import store from "store";
import {error} from "../../../helpers/notify";
import {notification} from "antd";

export class privateLoginController {

    data = {
        username: '',
        password: ''
    }

    constructor() {
        makeAutoObservable(this)
    }

    onChange(name: string, value: string) {
        // @ts-ignore
        this.data[name] = value
    }

    login(e: FormEvent) {
        e.preventDefault()
        let data = new FormData()
        data.append('username', this.data.username)
        data.append('password', sha1(this.data.password))

        request('post', '/admin/login', data)
            .then(result => {
                store.set('private', result)
                window.location.href = '/private'
            })
            .catch(err => {
                notification.error({
                    message: 'Ошибка авторизации',
                    description: 'Неверный логин и/или пароль'
                })
            })
    }

    logout() {
        store.remove('private')
        window.location.href = '/private/login'
    }

}
import { Controller } from '../../../helpers/Controller'
import { FormEvent } from 'react'
import sha1 from 'sha1'
import { request } from '../../../helpers/request'
import { makeObservable, observable } from 'mobx'

export class TypesController extends Controller {

    modalModel = false
    modalLocation = false

    model: any = {}
    location: any = {}

    models: any[] = []
    locations: any[] = []

    constructor() {
        super('/task/type', '/private/types')
        makeObservable(this, {
            models: observable,
            locations: observable,
            modalModel: observable,
            modalLocation: observable,
            model: observable,
            location: observable,
        })
    }

    saveModel() {
        const data = new FormData()
        data.append('name', this.model.name)
        data.append('typeid', this.element.id)

        request('post', this.model.id && this.model.id > 0 ? `/task/models/${this.model.id}` : `/task/models/0`, data)
            .then(result => {
                console.log(result)
                this.getModels(this.element.id)
                this.clearModel()
            })
    }

    saveLocation() {
        const data = new FormData()
        data.append('name', this.location.name)
        data.append('typeid', this.element.id)

        request('post', this.location.id && this.location.id > 0 ? `/task/location/${this.location.id}` : `/task/location/0`, data)
            .then(result => {
                console.log(result)
                this.getLocations(this.element.id)
                this.clearLocation()
            })
    }

    clearModel() {
        this.model = {}
        this.modalModel = false
    }

    clearLocation() {
        this.location = {}
        this.modalLocation = false
    }

    editModel(id: any = 0) {
        if(id > 0) {
            this.model = this.models.find(el => Number(el.id) === Number(id))
            console.log(this.model.id)
        }
        this.modalModel = true
    }

    editLocation(id: any = 0) {
        if(id > 0) {
            this.location = this.locations.find(el => Number(el.id) === Number(id))
        }
        this.modalLocation = true
    }

    onChangeModel(value: string) {
        this.model.name = value
    }

    onChangeLocation(value: string) {
        this.location.name = value
    }

    getModels(id: any) {
        if(id > 0) {
            request('get', `/task/models/${id}`)
                .then(result => {
                    console.log(result)
                    this.models = result[0]
                })
        }
    }

    getLocations(id: any) {
        if(id > 0) {
            request('get', `/task/location?filter[typeid]=${id}`)
                .then(result => {
                    console.log(result)
                    this.locations = result[0]
                })
        }
    }

    save(e: FormEvent) {
        e.preventDefault()

        const data = new FormData()
        data.append('name', this.element.name)
        data.append('consumptionrate', this.element.consumptionrate)

        this.store(data)
    }

}
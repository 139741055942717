import { observer } from 'mobx-react'
import { PrivateTitle } from '../../../components/PrivateTitle'
import React from 'react'

export const PrivateDashboard = observer(() => {

    return(
        <>
            <PrivateTitle
                title="Рабочий стол"
            />
        </>
    )
})
import { makeAutoObservable } from 'mobx'
import { FormEvent } from 'react'
import sha1 from 'sha1'
import { request } from '../../../helpers/request'
import store from 'store'
import { notification } from 'antd'

export class LoginController {

    data = {
        username: '',
        password: ''
    }

    company:any = {
        tarif: {
            tarif: {
                name: ''
            }
        },
        controllers: 0,
        taskactive: 0,
        taskend: 0
    }

    constructor() {
        makeAutoObservable(this)
    }

    onChange(name: string, value: string) {
        // @ts-ignore
        this.data[name] = value
    }

    login(e: FormEvent) {
        e.preventDefault()

        const data = new FormData()
        data.append('login', this.data.username)
        data.append('password', sha1(this.data.password))

        request('post', '/user/companylogin', data)
            .then(result => {
                store.set('public', result)
                window.location.href = '/'
            })
            .catch(err => {
                notification.error({
                    message: 'Ошибка авторизации',
                    description: 'Неверный логин и/или пароль'
                })
            })
    }

    logout() {
        store.remove('public')
        window.location.href = '/login'
    }

    getInfoByCompany() {
        request('get', `/task/main`)
            .then(result => {
                console.log(result)
                this.company = result
            })
    }

}
import { observer } from 'mobx-react'
import { TypesController } from './types.controller'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Col, Input, Pagination, Row, Space, Table, Modal, InputNumber } from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { remove } from '../../../helpers/remove'
import { PrivateTitle } from '../../../components/PrivateTitle'

const controller = new TypesController()

export const PrivateTypes = observer(() => {

    useEffect(() => {
        controller.get()
    }, [])

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            sorter: true,
        },
        { title: 'Наименование', dataIndex: 'name', key: 'name', sorter: true },
        {
            key: 'action',
            className: 'right',
            render: (text: any, record: any) => (
                <Space size="middle">
                    <Button
                        type="dashed"
                        shape="circle"
                        icon={<EditOutlined />}
                        href={`${controller.url}/${record.id}`}
                    />
                    <Button
                        type="dashed"
                        shape="circle"
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => remove('ksType', record.id, () => controller.get())}
                    />
                </Space>
            )
        }
    ]

    return(
        <>
            <PrivateTitle
                title="Типы счетчиков"
                buttons={[
                    { type: "link", title: "Создать", link: '/private/types/0', primary: true },
                ]}
            />

            <Table
                columns={columns}
                dataSource={controller.list}
                rowKey="id"
                pagination={false}
                onChange={(pagination: any, filters: any, sorter: any) => controller.onChangeSort(pagination, filters, sorter)}
            />

            <br />
            <Pagination
                defaultCurrent={controller.pagination.page}
                current={controller.pagination.page}
                defaultPageSize={controller.pagination.limit}
                pageSize={controller.pagination.limit}
                total={controller.pagination.total}
                showSizeChanger={false}
                onChange={(e) => controller.setPage(e)}
            />
        </>
    )
})

export const PrivateTypesElement = observer(() => {

    const { elementId } = useParams()

    useEffect(() => {
        controller.getElement(elementId)
        controller.getModels(elementId)
        controller.getLocations(elementId)
    }, [])

    const columnsModels = [
        { title: 'Наименование', dataIndex: 'name', key: 'name' },
        {
            key: 'action',
            className: 'right',
            render: (text: any, record: any) => (
                <Space size="middle">
                    <Button
                        type="dashed"
                        shape="circle"
                        icon={<EditOutlined />}
                        onClick={() => controller.editModel(record.id)}
                    />
                    <Button
                        type="dashed"
                        shape="circle"
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => remove('ksModelType', record.id, () => controller.getModels(elementId))}
                    />
                </Space>
            )
        }
    ]

    const columnsLocation = [
        { title: 'Наименование', dataIndex: 'name', key: 'name' },
        {
            key: 'action',
            className: 'right',
            render: (text: any, record: any) => (
                <Space size="middle">
                    <Button
                        type="dashed"
                        shape="circle"
                        icon={<EditOutlined />}
                        onClick={() => controller.editLocation(record.id)}
                    />
                    <Button
                        type="dashed"
                        shape="circle"
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => remove('ksLocationsType', record.id, () => controller.getLocations(elementId))}
                    />
                </Space>
            )
        }
    ]

    return(
        <>
            <form onSubmit={(e) => controller.save(e)}>
                <PrivateTitle
                    title="Редактирование типа счетчика"
                    buttons={[
                        { type: "link", title: "Назад", link: '/private/types' },
                        { type: "submit", title: "Сохранить", primary: true },
                    ]}
                />

                <Row gutter={[20, 20]}>
                    <Col span={24}>
                        <label>Наименование</label>
                        <Input
                            value={controller.element.name}
                            onChange={(e) => controller.onChange('name', e.target.value)}
                            required
                        />
                    </Col>
                    <Col span={24}>
                        <label>Норматив потребления</label>
                        <InputNumber
                            value={controller.element.consumptionrate}
                            onChange={(e) => controller.onChange('consumptionrate', e)}
                            required
                        />
                    </Col>
                    {controller.element.id && controller.element.id > 0 &&
                        <>
                            <Col span={24}>
                                <label>Модели</label>

                                <Button onClick={() => controller.editModel()}>Создать модель</Button>
                                <br/><br/>
                                <Table
                                    columns={columnsModels}
                                    dataSource={controller.models}
                                    rowKey="id"
                                    pagination={false}
                                />
                            </Col>
                            <Col span={24}>
                                <label>Расположения</label>

                                <Button onClick={() => controller.editLocation()}>Создать расположение</Button>
                                <br/><br/>
                                <Table
                                    columns={columnsLocation}
                                    dataSource={controller.locations}
                                    rowKey="id"
                                    pagination={false}
                                />
                            </Col>
                        </>
                    }
                </Row>

            </form>

            <Modal title="Редактирование модели" visible={controller.modalModel} onOk={() => controller.saveModel()} onCancel={() => controller.clearModel()}>
                <Row>
                    <Col span={24}>
                        <Input
                            value={controller.model.name}
                            onChange={(e) => controller.onChangeModel(e.target.value)}
                        />
                    </Col>
                </Row>
            </Modal>

            <Modal title="Редактирование местоположения" visible={controller.modalLocation} onOk={() => controller.saveLocation()} onCancel={() => controller.clearLocation()}>
                <Row>
                    <Col span={24}>
                        <Input
                            value={controller.location.name}
                            onChange={(e) => controller.onChangeLocation(e.target.value)}
                        />
                    </Col>
                </Row>
            </Modal>
        </>
    )
})
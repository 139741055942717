import axios from 'axios'
import { app } from '../config/app'
import store from 'store'
import { message, notification } from 'antd'

const getHeaders = (company: boolean = true) => {
    let headers = {
        'Type-Device': app.typeDevice,
    }

    if(company) {
        // @ts-ignore
        headers = { 'Type-Device': app.typeDevice, 'Companytoken': store.get('public') ? store.get('public').token : '' }
    }

    return headers
}

export const request = async (method: string, path: string, data: any = {}, company: boolean = true) => {

    const sender = axios.create({
        baseURL: app.uri,
        headers: getHeaders(company)
    })

    const copyError = (error: any) => {
        navigator.clipboard.writeText(error)
        message.success('Текст ошибки скопирован в буфер обмена');
    }

    switch (method) {
        case 'get':
            return sender.get(path)
                .then(result => {
                    console.log(result)
                    return result.data
                })
                .catch(error => {
                    console.log(error)
                    throw new Error(error)
                })
        case 'post':
            return sender.post(path, data)
                .then(result => {
                    console.log(result)
                    return result.data
                })
                .catch(error => {
                    console.log(error.response.data.message)
                    if(error.response)
                        notification.error({
                            message: 'Ошибка',
                            description: error.response && error.response.data && error.response.data.message && typeof error.response.data.message === 'string' ? error.response.data.message : '',
                            onClick: () => copyError(JSON.stringify(error.response))
                        })
                    throw new Error(error)
                })
        case 'delete':
            return sender.delete(path, data)
                .then(result => {
                    console.log(result)
                    return result.data
                })
                .catch(error => {
                    console.log(error)
                    throw new Error(error)
                })
    }
}